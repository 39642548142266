:root {
  --image-height: 343.599px;
  --image-width: 515.6px;
}

.result{
  padding-left: 20px;
}

.image-upload-container {
  width: var(--image-width);
  height: var(--image-height);
  border: 1px solid #ddd; /* Optional border for visualization */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures that image doesn't overflow the container */
  background-color: #f0f0f0; /* Optional background color */
}

.image-wrapper {
  position: relative;
  width: fit-content;
  height: auto;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  max-width: 100%;
  height: auto;
  max-height: var(--image-height);
  object-fit: contain; /* Ensures the image keeps its aspect ratio */
  top: 0;
  left: 0;
}

.face-frame {
  position: absolute;
  border: 2px solid #66bb6a; /* Border color for the face frame */
  box-sizing: border-box; /* Ensures the border is included in the size */
  pointer-events: none; /* Prevents the frame from interfering with image interactions */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Text color for contrast */
  font-weight: bold;
}


.estest_faceframe {
  transition: background-color 0.3s ease;
}

.estest_faceframe.hovered {
  border-color: #8e24aa; /* Hoặc bất kỳ màu nào bạn muốn */
}